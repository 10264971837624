<template>
  <div
    v-if="orderDetail"
    class="order-detail "
  >
    <!-- <pre>{{orderDetail}}</pre> -->

    <!-- <img height="1" width="1" style="display:none" :src="`https://www.facebook.com/tr?id=${savePixel}=&ev=PageView`"/> -->
    <div class="header-order mt-2 d-flex justify-space-between pe-3">
      <div class="header-order-text d-flex ms-3">
        <div class="header-order-navigate mt-2 me-2">
          <v-icon
            class=""
            color="#EA7C69"
            @click="backToOrder"
          >
            {{ icons.mdiArrowLeft }}
          </v-icon>
        </div>
        <div class="mt-2">
          <span class="header-title">Item Detail</span>
        </div>
      </div>
    </div>
    <div
      class="img-preview text-center mb-3"
    >
      <img
        :src="orderDetail.Photo !== null ? orderDetail.Photo : 'https://i.stack.imgur.com/81VAF.jpg'"
        alt="product picture"
        style="object-fit: cover;"
      />
    </div>
    <div class="order-pass">
      <div class="order-detail-data">
        <div class="order-list-item-menu d-flex ps-5 justify-space-between pe-8">
          <div class="d-flex">
            <div class="order-list-desc ms-3">
              <span class="order-list-desc-title">{{ orderDetail.Name }}</span>
              <p class="order-list-desc-text">
                {{ orderDetail.Category }}
              </p>
            </div>
          </div>

          <div class="order-list-price pt-2">
            <span
              class="order-list-desc-price"
            >Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(orderDetail['Base Price']) }}</span>
          </div>
        </div>
        <div class="ps-3 pe-3 desc-margin order-list-desc-full">
          <p class="order-list-desc-text-full ms-5 me-8">
            {{ orderDetail.Description }}
          </p>
        </div>
      </div>
      <!-- <img :src="`https://www.facebook.com/tr?id=${savePixel}
  &amp;ev=ViewContent
  &amp;cd[content_name]=${this.$store.getters['eOrdering/getsaveProductToVueX'].Name}
  &amp;cd[content_category]=${this.$store.getters['eOrdering/getsaveProductToVueX'].Category}
  &amp;cd[value]=${this.$store.getters['eOrdering/getsaveProductToVueX']['Base Price']}
  &amp;cd[currency]=IDR`" height="1" width="1" style="display:none"/> -->

      <div
        v-if="variant.length !== 0"
        class="order-variant mt-2 mb-3"
      >
        <div class="ps-5 pe-3">
          <span class="variant-title ms-3">Variant</span>
        </div>
        <div class="mt-3 ps-7">
          <v-radio-group
            v-model="userOrder.variant"
            class="mt-0"
            hide-details
          >
            <v-radio
              v-for="item in variant"
              :key="item.id"
              :value="item"
              color="#ea7c69"
              :disabled="item.hasOwnProperty('Out of Stock')"
              @click="selectVariant(item)"
            >
              <template
                #label
                class="d-flex label-data"
              >
                <v-row>
                  <v-col
                    class="order-list-selection"
                    cols="6"
                    :style="item.hasOwnProperty('Out of Stock') ? ' color: #5f5f5f': ''"
                  >
                    {{ item.Name }}
                  </v-col>

                  <v-col
                    class="order-list-selection-price text-end me-5"
                    cols="5"
                    :style="item.hasOwnProperty('Out of Stock') ? ' color: #5f5f5f': ''"
                  >
                    Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(item['Base Price']) }}
                  </v-col>
                </v-row>
              </template>
            </v-radio>
          </v-radio-group>
        </div>
      </div>
      <div
        v-if="addOn.length !== 0"
        class="order-variant mt-2 mb-3"
      >
        <div class="ps-5 pe-3">
          <span class="variant-title ms-3">Add on</span>
        </div>
        <div class="mt-3 ps-7">
          <v-row
            v-for="item in addOn"
            :key="item.id"
            :value="item"
          >
            <v-col
              class="order-list-selection"
              cols="6"
            >
              <v-checkbox
                v-model="userOrder.addOn"
                color="#ea7c69"
                :disabled="item.hasOwnProperty('Out of Stock')"
                hide-details
                :value="item"
                :label="`${item.Name}`"
                style="margin: 0 !important; padding: 0 !important"
                @click="selectAddOn"
              ></v-checkbox>
            </v-col>

            <v-col
              class="order-list-selection-price text-end me-5"
              cols="5"
              :style="item.hasOwnProperty('Out of Stock') ? ' color: #5f5f5f': ''"
            >
              + Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(item.Price) }}
            </v-col>
          </v-row>

          <!-- <v-radio-group class="mt-0" hide-details>
            <v-radio v-for="item in addOn" :key="item.id" :value="item" color="#ea7c69">
              <template #label class="d-flex label-data">
                <v-row>
                  <v-col class="order-list-selection" cols="6">{{ item.Name }}</v-col>

                  <v-col class="order-list-selection-price text-end me-5" cols="5"
                    >+ Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(item.Price) }}</v-col
                  >
                </v-row>
              </template>
            </v-radio>
          </v-radio-group> -->
        </div>
      </div>
    </div>

    <div class="order-variant mt-2 mb-3">
      <div class="ps-5 pe-3">
        <span class="variant-title ms-3">Qty</span>
      </div>
      <div class="">
        <div class="d-flex justify-center">
          <div>
            <v-icon
              class="me-5 qty-btn"
              color="white"
              @click="minusQty"
            >
              {{ icons.mdiMinus }}
            </v-icon>
          </div>
          <div class="mx-2 numberOrder">
            <p>{{ qtyitem }}</p>
          </div>
          <div>
            <v-icon
              class="ms-5 qty-btn"
              color="white"
              @click="plusQty"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div
        id="addToCartButton"
        class="btn-footer text-center"
        @click="addToCart(userOrder)"
      >
        <span
          class="footer-text"
        >Add to Order List - Rp {{ new Intl.NumberFormat(['ban', 'id']).format(this.userOrder.totalPrice) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mdiScriptTextOutline,
  mdiArrowLeft,
  mdiMagnify,
  mdiViewListOutline,
  mdiViewGridOutline,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'
import eFormMixin from '@/mixins/eFormMixin'

export default {
  setup() {
    return {
      icons: {
        mdiScriptTextOutline,
        mdiArrowLeft,
        mdiMagnify,
        mdiViewListOutline,
        mdiViewGridOutline,
        mdiPlus,
        mdiMinus,
      },
    }
  },
  mixins: [eFormMixin],
  data: () => ({
    saveDataDeatail: null,
    orderDetail: {},
    addOnVariantsDetail: [],
    addOn: [],
    variant: [],

    userOrder: {
      weight: 0,
      totalPrice: 0,
      addOn: [],
      variant: {},
    },
    extraAddOn: { id: 0, name: '', price: 0 },
    qtyitem: 1,
  }),
  async mounted() {
    this.pixelRun()

    // console.log(this.$store.getters['eOrdering/getFbPixelSave'], 'akjsajsiajsajisajisj')
    if (this.$store.getters['eOrdering/getFbPixelSave']) {
      this.savePixel = this.$store.getters['eOrdering/getFbPixelSave']
    } else {
      this.savePixel = null
    }
    await this.getOrderDetail()
    this.$vuetify.theme.isDark = true

    // console.log(this.variant, `INIVA`)

    // this.userOrder.totalPrice = this.orderDetail['Base Price'] * this.qtyitem

    // {
    //   name: 'Food 1',
    //   type: 'main dish',
    //   description: 'fresh food with premium ingredient',
    //   price: 175000,
    // variant: [
    //   { id: 1, name: 'Small', price: 175000 },
    //   { id: 2, name: 'Medium', price: 275000 },
    //   { id: 3, name: 'Large', price: 375000 },
    // ],
    // addOn: [{ id: 1, name: 'Extra Keju', price: 100 }],
    // }
    // this.userOrder.variant = this.orderDetail.variant[0]
  },

  methods: {
    pixelRun() {
      // console.log('masukkkkkkkkk')
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
      fbq('init', this.$store.getters['eOrdering/getFbPixelSave'])
      fbq('track', 'PageView')
      fbq('track', 'ViewContent', {
        content_name: this.$store.getters['eOrdering/getsaveProductToVueX'].Name,
        content_category: this.$store.getters['eOrdering/getsaveProductToVueX'].Category,
        value: this.$store.getters['eOrdering/getsaveProductToVueX']['Base Price'],
        currency: 'IDR',
        content_ids: this.$store.getters['eOrdering/getsaveProductToVueX'].id,
        content_type: 'product',
      })
    },
    selectAddOn() {
      // console.log(this.qtyitem, `ini item`)
      // console.log(this.userOrder.addOn)
      if (this.userOrder.addOn.length !== 0) {
        let countPriceAddon = 0
        let countWeightAddon = 0
        this.userOrder.addOn.forEach(el => {
          // console.log(el, `INI EL NYA`)
          countPriceAddon += el.Price * this.qtyitem
          if (el.hasOwnProperty('Weight(g)')) {
            // console.log('masuk ke properti')
            countWeightAddon += el['Weight(g)'] * this.qtyitem
          }
        })

        // console.log(countWeightAddon, `INI WEIGHT ADDON`)

        // console.log(countWeightAddon, `INI ADDON WEIHT`)
        this.userOrder.weight += countWeightAddon
        this.userOrder.totalPrice = this.userOrder.variant['Base Price'] * this.qtyitem + countPriceAddon
        if (this.userOrder.variant.hasOwnProperty('Weight(g)')) {
          // console.log(true, `masuk 1`)
          this.userOrder.weight = this.userOrder.variant['Weight(g)'] * this.qtyitem + countWeightAddon
        }
      } else {
        this.userOrder.totalPrice = this.userOrder.variant['Base Price'] * this.qtyitem
        if (this.userOrder.variant.hasOwnProperty('Weight(g)')) {
          // console.log(true, `masuk 2`)
          this.userOrder.weight = this.userOrder.variant['Weight(g)'] * this.qtyitem
        }
      }
    },
    selectVariant(item) {
      // console.log(item, 'aaaaaaaaaaaaaaaaaaaa')
      if (item.hasOwnProperty('Out of Stock')) {
        // console.log(item, '00000000000000000000000000')
      }
      this.userOrder.totalPrice = item['Base Price'] * this.qtyitem
      if (this.userOrder.variant.hasOwnProperty('Weight(g)')) {
        // console.log(true, `masuk 3`)
        this.userOrder.weight = this.userOrder.variant['Weight(g)'] * this.qtyitem
      }

      this.selectAddOn()
    },
    plusQty() {
      this.qtyitem += 1
      this.selectAddOn()
    },
    async getOrderDetail() {
      const data = await this.$store.dispatch('eOrdering/getSlugEorderingDetailItem', {
        slug: this.$route.params.name,
        params: this.$route.params.id,
      })

      // console.log(this.$route.params.name, data, this.$route.params.id, `INI DATA`)

      this.addOn = data.data.addOns
      this.variant = data.data.variant

      if (this.variant.length !== 0) {
        this.userOrder.variant = this.variant[0]

        this.userOrder.totalPrice = this.variant[0]['Base Price'] * this.qtyitem
      } else {
        this.userOrder.variant = data.data.shopDetail
        this.userOrder.totalPrice = data.data.shopDetail['Base Price'] * this.qtyitem
      }

      this.orderDetail = data.data.shopDetail
    },

    addToCart() {
      fbq('track', 'AddToCart', {
        content_name: this.orderDetail.Name,
        content_category: this.orderDetail.Category,
        value: this.orderDetail['Base Price'],
        currency: 'IDR',

        content_type: 'product',
      })

      const data = JSON.parse(localStorage.getItem(this.$route.params.name))

      // console.log(this.orderDetail)
      const addOnArr = []
      this.userOrder.addOn.forEach(el => {
        addOnArr.push(el.Name)
      })

      const payload = {
        qtyItem: this.qtyitem,
        weightItem: (this.userOrder.weight + this.orderDetail.Weight) * this.qtyitem,
        basePrice: this.orderDetail['Base Price'],
        totalPrice: this.userOrder.totalPrice,
        itemAddOn: addOnArr,

        itemDetail: this.orderDetail,
        addOn: this.userOrder.addOn,
        variant: this.userOrder.variant,
      }

      // console.log(payload, `INI PAYLOADNYA`)

      data.push(payload)
      localStorage.setItem(this.$route.params.name, JSON.stringify(data))

      this.backToOrder()
    },

    // setUserAddOn(item) {
    //   if (this.extraAddOn.id === item.id) {
    //     this.userOrder.addOn = { id: 0, name: '', price: 0 }
    //     this.extraAddOn = { id: 0, name: '', price: 0 }
    //   } else {
    //     this.extraAddOn = item
    //   }
    // },
    minusQty() {
      if (this.qtyitem === 1) {
        this.qtyitem = 1
      } else {
        this.qtyitem -= 1
        this.selectAddOn()
      }
    },
    backToOrder() {
      this.$router.push({ name: 'order' })
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,400;1,500;1,600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
.order-detail {
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}
.header-order {
  color: white;
}
.order-pass {
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
}
.header-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.img-preview {
  padding-top: 25px;
}
.img-preview img {
  width: 162px;
  height: 162px;
  border-radius: 8px;
}
.order-detail-data {
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: #2d303e;
}
.order-list-desc-title {
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
}
.order-list-desc-text {
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.order-list-desc-price {
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
}
.order-list-desc-full {
  margin-top: -16px;
}
.order-list-desc-text-full {
  font-family: 'Open Sans', sans-serif;

  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.order-variant {
  padding-top: 10px;
  padding-bottom: 10px;

  background-color: #2d303e;
}
.variant-title {
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
}
.order-list-selection {
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
}
.order-list-selection-price {
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
}
.qty-btn {
  background-color: #ea7c69;
  border-radius: 5px;
}
.numberOrder {
  color: white;
  padding-top: 1px;
}
.footer {
  padding: 12px 12px 8px 12px;
}
.btn-footer {
  color: white;
  padding: 12px;
  border-radius: 10px;
  background-color: #ea7c69;
}
/* @media (max-height: 3968px) {
  .order-detail {
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    padding-left: 30%;
    padding-right: 30%;
  }
  .img-preview img {
    width: 362px;
    height: 362px;
    border-radius: 8px;
  }
} */

.desc-margin {
  margin-top: -6px;
}
</style>
